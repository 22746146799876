import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomBoxShadow from '../lib/generate/box-shadow'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ boxShadow, container, canvas }) => (
  <div
    css={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'space-around',
      ...canvas
    }}
  >
    <div
      css={{
        margin: 'auto',
        boxShadow: boxShadow.boxShadow,
        ...container
      }}
    />
  </div>
)

const GridItem = ({ boxShadow, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '48px',
      paddingRight: '48px',
      paddingTop: '32px',
      height: '19vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'block',
        height: '100%',
        width: '100%'
      }}
      {...props}
    >
      <div
        css={{
          ...boxShadow,
          height: '100%',
          width: '100%'
        }}
      ></div>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(20)
      .fill(0)
      .map((_, i) => {
        const baseBoxShadow = randomBoxShadow()
        const boxShadowWithPins = { ...baseBoxShadow, ...pins }
        const boxShadow = {
          ...boxShadowWithPins,
          boxShadow: randomBoxShadow.toBoxShadow(boxShadowWithPins)
        }

        return (
          <GridItem
            key={i}
            onClick={() => onSelect(boxShadow)}
            boxShadow={boxShadow}
          />
        )
      })}
  </Flex>
)

const Sidebar = ({
  onChange,
  onCanvasChange,
  onContainerChange,
  value,
  canvas,
  container,
  pins,
  onPin
}) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }
  const changeContainer = key => e => {
    onContainerChange(key, e.target.value)
  }
  const changeCanvas = key => e => {
    console.log(e)
    onCanvasChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Box Shadow</SidebarHeading>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.inset}
          onClick={() => onPin('inset', value.inset)}
        />
        <Label>Inset</Label>
        <Input
          mr={2}
          checked={value.inset}
          type="checkbox"
          onChange={() => {
            onChange('inset', !value.inset)
          }}
        />
      </Flex>
      <Flex>
        <Lock
          bg="transparent"
          active={pins.offsetX}
          onClick={() => onPin('offsetX', value.offsetX)}
        />
        <Label>Offset X</Label>
        <Input
          min={-100}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.offsetX)}
          type="range"
          onChange={changeValue('offsetX')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.offsetX}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.offsetY}
          onClick={() => onPin('offsetY', value.offsetY)}
        />
        <Label>Offset Y</Label>
        <Input
          min={-100}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.offsetY)}
          type="range"
          onChange={changeValue('offsetY')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.offsetY}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.blur}
          onClick={() => onPin('blur', value.blur)}
        />
        <Label>Blur</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.blur)}
          type="range"
          onChange={changeValue('blur')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.blur}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spread}
          onClick={() => onPin('spread', value.spread)}
        />
        <Label>Spread</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.spread)}
          type="range"
          onChange={changeValue('spread')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spread}
        />
      </Flex>
      <Color
        value={value.color}
        onChange={changeValue('color')}
        active={pins.color}
        onLock={() => onPin('color', value.color)}
        width={128}
      />
      <SectionBorder my={4} />
      <H5 mb={1}>Container</H5>
      <Flex py={1} alignItems="center">
        <Label>Height</Label>
        <Input
          min={0}
          max={800}
          steps={1}
          width={1}
          mr={2}
          value={Number(container.height)}
          type="range"
          onChange={changeContainer('height')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={container.height}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Label>Width</Label>
        <Input
          min={0}
          max={800}
          steps={1}
          width={1}
          mr={2}
          value={Number(container.width)}
          type="range"
          onChange={changeContainer('width')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={container.width}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Label>Border Radius</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(container.borderRadius)}
          type="range"
          onChange={changeContainer('borderRadius')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={container.borderRadius}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Canvas</H5>
      <Color
        value={canvas.backgroundColor || '#ffffff'}
        onChange={changeCanvas('backgroundColor')}
        onPin={() => {}}
        showLock={false}
        width={128}
      />
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={'box-shadow: ' + randomBoxShadow.toBoxShadow(value)}
      />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import styled from '@emotion/styled'\n\n" +
          "const BoxShadow = styled('div')({\n" +
          `  boxShadow: '${randomBoxShadow.toBoxShadow(value)}'` +
          '\n})\n'
        }
      />
    </>
  )
}

export default ({ initialBoxShadow, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('boxShadowVotes'))
  } catch (e) {}

  const [boxShadow, setBoxShadow] = useState(
    initialBoxShadow || randomBoxShadow()
  )
  const [pins, setPins] = useState(initialPins || {})
  const [canvas, setCanvas] = useState({})
  const [container, setContainer] = useState({
    height: 100,
    width: 100,
    borderRadius: 0
  })
  const [gridView, setGridView] = useState(false)

  const boxShadowWithPins = { ...boxShadow, ...pins }
  const fullBoxShadow = {
    ...boxShadowWithPins,
    boxShadow: randomBoxShadow.toBoxShadow(boxShadowWithPins)
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('boxShadows')
      .collection('votes')
      .add({
        up: true,
        data: fullBoxShadow,
        metadata: { pins, container }
      })

    localStorage.setItem(
      'boxShadowVotes',
      JSON.stringify(
        [
          {
            id,
            boxShadow: fullBoxShadow.boxShadow
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setBoxShadow(randomBoxShadow())
  }
  const skip = () => {
    setBoxShadow(randomBoxShadow())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = boxShadow => {
    setBoxShadow(boxShadow)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main
            boxShadow={fullBoxShadow}
            container={container}
            canvas={canvas}
          />
        )
      }
      sidebar={
        <Sidebar
          value={fullBoxShadow}
          container={container}
          canvas={canvas}
          pins={pins}
          onChange={(key, value) => {
            setBoxShadow({
              ...boxShadow,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onContainerChange={(key, value) => {
            setContainer({
              ...container,
              [key]: Number(value)
            })
          }}
          onCanvasChange={(key, value) => {
            setCanvas({
              ...canvas,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/box-shadows/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton
              disabled={gridView}
              css={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  opacity: 0.4
                }
              }}
              onClick={upvote}
            >
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
